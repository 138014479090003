@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-table-body {
  @apply overflow-y-scroll max-h-[calc(100%-400px)];
}
.rag-progress .ant-progress-text {
  width: 50px;
  border: 0px solid #f00;
}
.rag-progress .ant-progress-outer {
  margin-inline-end: calc(-50px - 8px) !important;
  padding-inline-end: calc(50px + 8px) !important;
}
.ragDisable {
  cursor: not-allowed !important;
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    top: 0px;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    left: 0;
  }
}
